console.log('JavaScript MyApp Loaded!')
import {ioiC} from './colors.js'
import {arrObjects} from './scene_objects.js'
import initScene from './scene.js'
import {scene, camera, renderer} from './scene.js'
// .: Global Variables
// let myOs, divActor, divTheatre, divHead, divLogo, divFoot,
// divLang, langIcon, langMenu;

// let btnNL, btnGR, btnEN;

// let userLanguage = localStorage.getItem("siteLang");
// let defaultLanguage = "EN";
let divApp, divHero, heroLeft, heroRight, canvasEarth,
    section1, section2, section3

let initPage = 'test'
let MyApp = function(initLoad){
    console.log('MyApp Loaded!: ' + initLoad)

    /** .: CREATE - UI Elements :.**/

    // function createHeadBasic(actor){
    //     let imgSrc;
    //     if (actor === "recipe-management"){
    //         imgSrc = "box/img/logo.png";
    //     }else{
    //         imgSrc = "../img/logo.png";
    //     }
    //
    //     divHead = document.getElementById("div-head");
    //
    //     divLang = document.getElementById("div-language");
    //     langIcon = document.getElementById("div-icon-language");
    //     langMenu = document.getElementById("div-menu-language");
    //     createLanguageMenu(actor);
    //
    //     let divHeadLogo = document.getElementById("div-head-logo");
    //     divLogo = document.createElement('img');
    //     divLogo.style.position = "relative";
    //     divLogo.style.height = "100%";
    //     divLogo.src = imgSrc;
    //     divHeadLogo.appendChild(divLogo);
    // }
    //
    // function createLanguageMenu(actor){
    //     let imgSrcNL, imgSrcGR, imgSrcEN;
    //     if (actor === "recipe-management"){
    //         imgSrcNL = "box/img/flags/icon_Flag_NL.png";
    //         imgSrcGR = "box/img/flags/icon_Flag_GR.png";
    //         imgSrcEN = "box/img/flags/icon_Flag_EN.png";
    //     }else{
    //         imgSrcNL = "../img/flags/icon_Flag_NL.png";
    //         imgSrcGR = "../img/flags/icon_Flag_GR.png";
    //         imgSrcEN = "../img/flags/icon_Flag_EN.png";
    //     }
    //
    //     btnNL = document.createElement('button');
    //     btnNL.id = "btn-NL";
    //     btnNL.className = "lang-btn";
    //     btnNL.style.position = "relative";
    //     // btnNL.style.display = "none";
    //     btnNL.style.width = 100 + "%";
    //     btnNL.style.height = 3 + "vh";
    //     btnNL.style.border = "none";
    //     btnNL.style.backgroundColor = "rgba(255,255,255, 0.0)";
    //     btnNL.style.backgroundImage = "url(" + imgSrcNL + ")";
    //     btnNL.style.backgroundRepeat = "no-repeat";
    //     btnNL.style.backgroundPosition = "center center";
    //     btnNL.style.backgroundSize = "95%";
    //     langMenu.appendChild(btnNL);
    //
    //     btnGR = document.createElement('button');
    //     btnGR.id = "btn-GR";
    //     btnGR.className = "lang-btn";
    //     btnGR.style.position = "relative";
    //     // btnGR.style.display = "none";
    //     btnGR.style.width = 100 + "%";
    //     btnGR.style.height = 3 + "vh";
    //     btnGR.style.border = "none";
    //     btnGR.style.backgroundColor = "rgba(255,255,255, 0.0)";
    //     btnGR.style.backgroundImage = "url(" + imgSrcGR + ")";
    //     btnGR.style.backgroundRepeat = "no-repeat";
    //     btnGR.style.backgroundPosition = "center center";
    //     btnGR.style.backgroundSize = "95%";
    //     langMenu.appendChild(btnGR);
    //
    //     btnEN = document.createElement('button');
    //     btnEN.id = "btn-EN";
    //     btnEN.className = "lang-btn";
    //     btnEN.style.position = "relative";
    //     // btnEN.style.display = "none";
    //     btnEN.style.width = 100 + "%";
    //     btnEN.style.height = 3 + "vh";
    //     btnEN.style.border = "none";
    //     btnEN.style.backgroundColor = "rgba(255,255,255, 0.0)";
    //     btnEN.style.backgroundImage = "url(" + imgSrcEN + ")";
    //     btnEN.style.backgroundRepeat = "no-repeat";
    //     btnEN.style.backgroundPosition = "center center";
    //     btnEN.style.backgroundSize = "95%";
    //     langMenu.appendChild(btnEN);
    // }
    //
    // function createFootBasic(){
    //     divFoot = document.getElementById("div-foot");
    //     let footTitle = document.getElementById('foot-title');
    //     footTitle.innerHTML = myCredits;
    // }

    /** .: FETCH - Elements :.**/
    let divClient = document.querySelector('#client-info')
    divClient.style.marginBottom = 3 + 'vh'
    let clientOs = document.querySelector('#system-os')
    let clientBrowser = document.querySelector('#browser')
    let clientPixels = document.querySelector('#pixels')


    divApp = document.querySelector('#div-app')
    divHero = document.querySelector('#div-hero')
    // divHero.style.display = 'none'
    heroLeft = document.querySelector('#hero-left')
    let heroTxtChallenge = document.querySelector('#challenging')
    let heroTxtBy = document.querySelector('#by')
    let heroTxtGlobal = document.querySelector('#global')
    let heroTxtText = document.querySelector('#hero-text')

    heroRight = document.querySelector('#hero-right')
    canvasEarth = document.querySelector('#canvas-earth')
    section1 = document.querySelector('#section1')
    section2 = document.querySelector('#section2')
    section3 = document.querySelector('#section3')

    /** .: APP - Initial Run :.**/
    HTML(initLoad);
    INIT(initLoad);


    // function fetchActorIndex(){
    //     divActor =  document.getElementById("actor-recipe-management");
    // }
    // function fetchActorAddRecipe(){
    //     divActor =  document.getElementById("actor-add-recipe");
    // }
    // function fetchActorEditRecipe(){
    //     divActor =  document.getElementById("actor-edit-recipe");
    // }
    // function fetchActorViewRecipe(){
    //     divActor =  document.getElementById("actor-view-recipe");
    // }

    /** .: SETUP - UI Elements :.**/
    // function initialDisplayActor(actor){
    //     let myString = "actor-" + actor;
    //     document.getElementById(myString).style.display = "flex";
    // }
    // function renameBtnByImgLoadStatus(){
    //     console.log("RENAME BUTTON BY IMAGE STAUS - Loaded / Not Loaded");
    //     let myBtn = document.getElementById("btn-upl-recipe-img");
    //     let myImgDiv = document.getElementById("div-img-recipe");
    //     let myStepsDiv = document.getElementById("div-steps-block");
    //
    //     let myCheckRecipe = myImgDiv.children.length;
    //     // console.log("div-img-recipe Children: " + myCheckRecipe);
    //     if(myCheckRecipe === 0){
    //         myBtn.innerText = "Upload Image";
    //         myBtn.style.backgroundColor = ioiC.btnUploadColor;
    //         myBtn.style.color = ioiC.btnUploadTxtColor;
    //     }else{
    //         myBtn.innerText = "Replace Image";
    //         myBtn.style.backgroundColor = ioiC.placeholder;
    //         myBtn.style.color = "white";
    //         myBtn.className = "btn-replace-img";
    //     }
    //
    //     let myChildrenSteps = myStepsDiv.children.length;
    //     // let myChildrenSteps = myStepsDiv.children;
    //     // console.log("div-steps-block: " + myChildrenSteps);
    //     for(let i = 0; (i < myChildrenSteps); i++){
    //         if (i >= 1){
    //             let myRow = myStepsDiv.children[i];
    //             // console.log("My Row ClassName: " + myRow.className);
    //             let myImgStepDiv = myRow.children[2];
    //             let myStepBtn = myRow.children[1];
    //             // console.log("My Row ClassName: " + myImgStepDiv.className);
    //             // console.log("My Row Button: " + myStepBtn.id);
    //
    //             let myCheckStep = myImgStepDiv.children.length;
    //             // console.log("div-img-step Children: " + myCheckStep);
    //
    //             if(myCheckStep === 0){
    //                 myStepBtn.innerText = "Upload Image";
    //                 myStepBtn.style.backgroundColor = ioiC.btnUploadColor;
    //                 myStepBtn.style.color = ioiC.btnUploadTxtColor;
    //             }else{
    //                 myStepBtn.innerText = "Replace Image";
    //                 myStepBtn.style.backgroundColor = ioiC.placeholder;
    //                 myStepBtn.style.color = "white";
    //                 myStepBtn.className = "btn-replace-step-img";
    //             }
    //         }
    //     }
    // }

    /** .: EVENT - Listeners :.**/
    function LISTENER(actor){

        // function btnHoverUI(){
        //     // .: HOVER - 2 Color - Listener :.
        //     let btnUI = document.querySelectorAll('.btn-ui, .btn-ui-large');
        //     btnUI.forEach(elem=>{
        //         elem.addEventListener('mouseover', function() {
        //             elem.style.backgroundColor = ioiC.btnUiColorHover;
        //         });
        //         elem.addEventListener('mouseout', function() {
        //             elem.style.backgroundColor = ioiC.btnUiColor;
        //         });
        //     });
        //
        //     // .: HOVER - 3 Color - Listener :.
        //     const BtnsUploadImg = document.querySelectorAll(".btn-upload-step-img, .btn-replace-step-img, .btn-upload-img, .btn-replace-img");
        //     BtnsUploadImg.forEach(elem=>{
        //         let myInColor = elem.style.backgroundColor;
        //
        //         elem.addEventListener('mouseover', function() {
        //             // console.log("IN Over Color: " + myInColor)
        //             elem.style.backgroundColor = ioiC.btnUiColorHover;
        //             elem.style.color = "white";
        //         });
        //         elem.addEventListener('mouseout', function() {
        //             // console.log("IN Out Color: " + myInColor)
        //             if(myInColor === ioiC.btnUploadColor){
        //                 elem.style.backgroundColor = ioiC.btnUploadColor;
        //                 elem.style.color = "white";
        //             }
        //             else if(myInColor === ioiC.btnUploadDoneColor){
        //                 elem.style.backgroundColor = ioiC.btnUploadDoneColor;
        //                 elem.style.color = "white";
        //
        //             }else{
        //                 elem.style.backgroundColor = ioiC.btnUploadColor;
        //                 elem.style.color = "white";
        //             }
        //         });
        //     });
        // }
        // btnHoverUI();


        /** .: CLICK - Functions :.**/
        // function clickHome(){
        //     window.location = '../../index.php';
        // }

        // function clickAddRecipe(){
        //     window.location = './box/php/add-recipe.php';
        // }


         /** .: INPUT - Listeners :.**/
        // function changeSelectColor(){
        //     this.style.color = ioiC.inpTxtSelectColor;
        //     this.style.border = "none";
        //     this.style.outline = "none";
        // }

        // function changeClockColor(){
        //     this.style.color = ioiC.inpTxtSelectColor;
        // }

        // function inputUI(){
        //     const selectInputColor = document.querySelectorAll('select');
        //     selectInputColor.forEach(function(elem) {
        //         elem.addEventListener('change', changeSelectColor);
        //         if(elem.value !== "")
        //         {
        //             elem.style.color = ioiC.inpTxtSelectColor;
        //         }
        //     });
        //
        //     const clockInputColor = document.querySelectorAll('input[type="time"]');
        //     clockInputColor.forEach(function(elem) {
        //         elem.addEventListener('change', changeClockColor);
        //         if(elem.value !== "00:00")
        //         {
        //             elem.style.color = ioiC.inpTxtSelectColor;
        //         }
        //     });
        // }
        // inputUI();

         /** .: Language Selector :.**/
        // divLang.addEventListener("mouseover", function (){
        //     langMenu.style.display = 'flex';
        //     btnLangHover();
        // });

        // divLang.addEventListener("mouseleave", function (){
        //     langMenu.style.display = 'none';
        // });

        // function btnLangHover(){
        //     let btnLang = document.querySelectorAll('.lang-btn');
        //     btnLang.forEach(elem=>{
        //         elem.addEventListener('mouseover', function() {
        //             elem.style.backgroundColor = "rgba(255,255,255, 1.0)";
        //         });
        //         elem.addEventListener('mouseleave', function() {
        //             elem.style.backgroundColor = "rgba(255,255,255, 0.0)";
        //         });
        //
        //     });
        // }

        // function clickBtnLanguageMenu(elem){
        //     // console.log('My Click: ' + elem.id)
        //     let siteLanguage = "";
        //     langIcon.innerHTML = "";
        //     let siteLangElem = document.getElementById('my-set-language');
        //
        //     if (elem === "NL"){
        //         siteLanguage = "NL";
        //         localStorage.setItem("siteLang", siteLanguage);
        //         siteLangElem.value = siteLanguage;
        //
        //         let clonedNode = btnNL.cloneNode(true);
        //         clonedNode.style.backgroundColor = "rgba(255,255,255, 0.0)";
        //         langIcon.appendChild(clonedNode);
        //     }
        //     else if (elem === "GR"){
        //         siteLanguage = "GR";
        //         localStorage.setItem("siteLang", siteLanguage);
        //         siteLangElem.value = siteLanguage;
        //
        //         let clonedNode = btnGR.cloneNode(true);
        //         clonedNode.style.backgroundColor = "rgba(255,255,255, 0.0)";
        //         langIcon.appendChild(clonedNode);
        //     }
        //     else if (elem === "EN"){
        //         siteLanguage = "EN";
        //         localStorage.setItem("siteLang", siteLanguage);
        //         siteLangElem.value = siteLanguage;
        //
        //         let clonedNode = btnEN.cloneNode(true);
        //         clonedNode.style.backgroundColor = "rgba(255,255,255, 0.0)";
        //         langIcon.appendChild(clonedNode);
        //     }
        //     runLanguageChange(siteLanguage, actor);
        // }

        // btnNL.addEventListener('click', function() {clickBtnLanguageMenu("NL");});
        // btnGR.addEventListener('click', function() {clickBtnLanguageMenu("GR");});
        // btnEN.addEventListener('click', function() {clickBtnLanguageMenu("EN");});

        // function runLanguageChange(runLang, actor){
        //
        //     function selectEN(){
        //         for(let c = 0; c < enDbIndexSelectDish.length; c++){
        //             let elem = enDbIndexSelectDish[c];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //         for(let d = 0; d < enDbIndexSelectMeal.length; d++){
        //             let elem = enDbIndexSelectMeal[d];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //         for(let e = 0; e < enDbIndexSelectCuisine.length; e++){
        //             let elem = enDbIndexSelectCuisine[e];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //         for(let f = 0; f < enDbIndexSelectMethod.length; f++){
        //             let elem = enDbIndexSelectMethod[f];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //         for(let g = 0; g < enDbIndexSelectDiet.length; g++){
        //             let elem = enDbIndexSelectDiet[g];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //         for(let h = 0; h < enDbIndexSelectNutrition.length; h++){
        //             let elem = enDbIndexSelectNutrition[h];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //         for(let i = 0; i < enDbIndexSelectMoment.length; i++){
        //             let elem = enDbIndexSelectMoment[i];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //         for(let j = 0; j < enDbIndexSelectSeason.length; j++){
        //             let elem = enDbIndexSelectSeason[j];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //         for(let k = 0; k < enDbIndexSelectOccasion.length; k++){
        //             let elem = enDbIndexSelectOccasion[k];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //     }
        //     function selectSkipEN(){
        //         for(let c = 0; c < enDbIndexSelectDish.length; c++){
        //             if(c !== 0){
        //                 let elem = enDbIndexSelectDish[c];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //
        //         }
        //         for(let d = 0; d < enDbIndexSelectMeal.length; d++){
        //             if(d !== 0){
        //                 let elem = enDbIndexSelectMeal[d];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //         for(let e = 0; e < enDbIndexSelectCuisine.length; e++){
        //             if(e !== 0) {
        //                 let elem = enDbIndexSelectCuisine[e];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //         for(let f = 0; f < enDbIndexSelectMethod.length; f++){
        //             if(f !== 0) {
        //                 let elem = enDbIndexSelectMethod[f];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //         for(let g = 0; g < enDbIndexSelectDiet.length; g++){
        //             if(g !== 0) {
        //                 let elem = enDbIndexSelectDiet[g];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //         for(let h = 0; h < enDbIndexSelectNutrition.length; h++){
        //             if(h !== 0) {
        //                 let elem = enDbIndexSelectNutrition[h];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //         for(let i = 0; i < enDbIndexSelectMoment.length; i++){
        //             if(i !== 0) {
        //                 let elem = enDbIndexSelectMoment[i];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //         for(let j = 0; j < enDbIndexSelectSeason.length; j++){
        //             if(j !== 0) {
        //                 let elem = enDbIndexSelectSeason[j];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //         for(let k = 0; k < enDbIndexSelectOccasion.length; k++){
        //             if(k !== 0) {
        //                 let elem = enDbIndexSelectOccasion[k];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //     }
        //     function inputsRecipeEN(){
        //         let myReqDB = enDbAddRequirePlace[0]
        //         const myReq = document.querySelectorAll(myReqDB[0]);
        //         myReq.forEach(elem=> {
        //             elem.placeholder = myReqDB[1];
        //         })
        //
        //         let myIngDB = enDbAddIngredientPlace[0]
        //         const myIngr = document.querySelectorAll(myIngDB[0]);
        //         myIngr.forEach(elem=> {
        //             elem.placeholder = myIngDB[1];
        //         })
        //
        //         let myAmtDB = enDbAddAmountPlace[0]
        //         const myAmt = document.querySelectorAll(myAmtDB[0]);
        //         myAmt.forEach(elem=> {
        //             elem.placeholder = myAmtDB[1];
        //         })
        //     }
        //     function selectNL(){
        //         for(let c = 0; c < nlDbIndexSelectDish.length; c++){
        //             let elem = nlDbIndexSelectDish[c];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //         for(let d = 0; d < nlDbIndexSelectMeal.length; d++){
        //             let elem = nlDbIndexSelectMeal[d];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //         for(let e = 0; e < nlDbIndexSelectCuisine.length; e++){
        //             let elem = nlDbIndexSelectCuisine[e];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //         for(let f = 0; f < nlDbIndexSelectMethod.length; f++){
        //             let elem = nlDbIndexSelectMethod[f];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //         for(let g = 0; g < nlDbIndexSelectDiet.length; g++){
        //             let elem = nlDbIndexSelectDiet[g];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //         for(let h = 0; h < nlDbIndexSelectNutrition.length; h++){
        //             let elem = nlDbIndexSelectNutrition[h];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //         for(let i = 0; i < nlDbIndexSelectMoment.length; i++){
        //             let elem = nlDbIndexSelectMoment[i];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //         for(let j = 0; j < nlDbIndexSelectSeason.length; j++){
        //             let elem = nlDbIndexSelectSeason[j];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //         for(let k = 0; k < nlDbIndexSelectOccasion.length; k++){
        //             let elem = nlDbIndexSelectOccasion[k];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //     }
        //     function selectSkipNL(){
        //         for(let c = 0; c < nlDbIndexSelectDish.length; c++){
        //             if(c !== 0){
        //                 let elem = nlDbIndexSelectDish[c];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //
        //         }
        //         for(let d = 0; d < nlDbIndexSelectMeal.length; d++){
        //             if(d !== 0){
        //                 let elem = nlDbIndexSelectMeal[d];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //         for(let e = 0; e < nlDbIndexSelectCuisine.length; e++){
        //             if(e !== 0) {
        //                 let elem = nlDbIndexSelectCuisine[e];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //         for(let f = 0; f < nlDbIndexSelectMethod.length; f++){
        //             if(f !== 0) {
        //                 let elem = nlDbIndexSelectMethod[f];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //         for(let g = 0; g < nlDbIndexSelectDiet.length; g++){
        //             if(g !== 0) {
        //                 let elem = nlDbIndexSelectDiet[g];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //         for(let h = 0; h < nlDbIndexSelectNutrition.length; h++){
        //             if(h !== 0) {
        //                 let elem = nlDbIndexSelectNutrition[h];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //         for(let i = 0; i < nlDbIndexSelectMoment.length; i++){
        //             if(i !== 0) {
        //                 let elem = nlDbIndexSelectMoment[i];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //         for(let j = 0; j < nlDbIndexSelectSeason.length; j++){
        //             if(j !== 0) {
        //                 let elem = nlDbIndexSelectSeason[j];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //         for(let k = 0; k < nlDbIndexSelectOccasion.length; k++){
        //             if(k !== 0) {
        //                 let elem = nlDbIndexSelectOccasion[k];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //     }
        //     function inputsRecipeNL(){
        //         let myReqDB = nlDbAddRequirePlace[0]
        //         const myReq = document.querySelectorAll(myReqDB[0]);
        //         myReq.forEach(elem=> {
        //             elem.placeholder = myReqDB[1];
        //         })
        //
        //         let myIngDB = nlDbAddIngredientPlace[0]
        //         const myIngr = document.querySelectorAll(myIngDB[0]);
        //         myIngr.forEach(elem=> {
        //             elem.placeholder = myIngDB[1];
        //         })
        //
        //         let myAmtDB = nlDbAddAmountPlace[0]
        //         const myAmt = document.querySelectorAll(myAmtDB[0]);
        //         myAmt.forEach(elem=> {
        //             elem.placeholder = myAmtDB[1];
        //         })
        //     }
        //     function selectGR(){
        //         for(let c = 0; c < grDbIndexSelectDish.length; c++){
        //             let elem = grDbIndexSelectDish[c];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //         for(let d = 0; d < grDbIndexSelectMeal.length; d++){
        //             let elem = grDbIndexSelectMeal[d];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //         for(let e = 0; e < grDbIndexSelectCuisine.length; e++){
        //             let elem = grDbIndexSelectCuisine[e];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //         for(let f = 0; f < grDbIndexSelectMethod.length; f++){
        //             let elem = grDbIndexSelectMethod[f];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //         for(let g = 0; g < grDbIndexSelectDiet.length; g++){
        //             let elem = grDbIndexSelectDiet[g];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //         for(let h = 0; h < grDbIndexSelectNutrition.length; h++){
        //             let elem = grDbIndexSelectNutrition[h];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //         for(let i = 0; i < grDbIndexSelectMoment.length; i++){
        //             let elem = grDbIndexSelectMoment[i];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //         for(let j = 0; j < grDbIndexSelectSeason.length; j++){
        //             let elem = grDbIndexSelectSeason[j];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //         for(let k = 0; k < grDbIndexSelectOccasion.length; k++){
        //             let elem = grDbIndexSelectOccasion[k];
        //             document.getElementById(elem[0]).innerHTML = elem[1];
        //         }
        //     }
        //     function selectSkipGR(){
        //         for(let c = 0; c < grDbIndexSelectDish.length; c++){
        //             if(c !== 0){
        //                 let elem = grDbIndexSelectDish[c];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //
        //         }
        //         for(let d = 0; d < grDbIndexSelectMeal.length; d++){
        //             if(d !== 0){
        //                 let elem = grDbIndexSelectMeal[d];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //         for(let e = 0; e < grDbIndexSelectCuisine.length; e++){
        //             if(e !== 0) {
        //                 let elem = grDbIndexSelectCuisine[e];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //         for(let f = 0; f < grDbIndexSelectMethod.length; f++){
        //             if(f !== 0) {
        //                 let elem = grDbIndexSelectMethod[f];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //         for(let g = 0; g < grDbIndexSelectDiet.length; g++){
        //             if(g !== 0) {
        //                 let elem = grDbIndexSelectDiet[g];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //         for(let h = 0; h < grDbIndexSelectNutrition.length; h++){
        //             if(h !== 0) {
        //                 let elem = grDbIndexSelectNutrition[h];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //         for(let i = 0; i < grDbIndexSelectMoment.length; i++){
        //             if(i !== 0) {
        //                 let elem = grDbIndexSelectMoment[i];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //         for(let j = 0; j < grDbIndexSelectSeason.length; j++){
        //             if(j !== 0) {
        //                 let elem = grDbIndexSelectSeason[j];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //         for(let k = 0; k < grDbIndexSelectOccasion.length; k++){
        //             if(k !== 0) {
        //                 let elem = grDbIndexSelectOccasion[k];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //     }
        //     function inputsRecipeGR(){
        //         let myReqDB = grDbAddRequirePlace[0]
        //         const myReq = document.querySelectorAll(myReqDB[0]);
        //         myReq.forEach(elem=> {
        //             elem.placeholder = myReqDB[1];
        //         })
        //
        //         let myIngDB = grDbAddIngredientPlace[0]
        //         const myIngr = document.querySelectorAll(myIngDB[0]);
        //         myIngr.forEach(elem=> {
        //             elem.placeholder = myIngDB[1];
        //         })
        //
        //         let myAmtDB = grDbAddAmountPlace[0]
        //         const myAmt = document.querySelectorAll(myAmtDB[0]);
        //         myAmt.forEach(elem=> {
        //             elem.placeholder = myAmtDB[1];
        //         })
        //     }
        //
        //     if (actor === "recipe-management"){
        //         if (runLang === "EN") {
        //             for(let a = 0; a < enDbIndex.length; a++){
        //                 let elem = enDbIndex[a];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //             for(let b = 0; b < enDbIndexInputText.length; b++){
        //                 let elem = enDbIndexInputText[b];
        //                 document.getElementById(elem[0]).placeholder = elem[1];
        //             }
        //
        //             selectEN();
        //
        //             for(let l = 0; l < enDbIndexSelectQuick.length; l++){
        //                 let elem = enDbIndexSelectQuick[l];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //         if (runLang === "NL") {
        //             for(let a = 0; a < nlDbIndex.length; a++){
        //                 let elem = nlDbIndex[a];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //             for(let b = 0; b < nlDbIndexInputText.length; b++){
        //                 let elem = nlDbIndexInputText[b];
        //                 document.getElementById(elem[0]).placeholder = elem[1];
        //             }
        //
        //             selectNL();
        //
        //             for(let l = 0; l < nlDbIndexSelectQuick.length; l++){
        //                 let elem = nlDbIndexSelectQuick[l];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //         if (runLang === "GR") {
        //             for(let a = 0; a < grDbIndex.length; a++){
        //                 let elem = grDbIndex[a];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //             for(let b = 0; b < grDbIndexInputText.length; b++){
        //                 let elem = grDbIndexInputText[b];
        //                 document.getElementById(elem[0]).placeholder = elem[1];
        //             }
        //
        //             selectGR();
        //
        //             for(let l = 0; l < grDbIndexSelectQuick.length; l++){
        //                 let elem = grDbIndexSelectQuick[l];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //     }
        //
        //     if (actor === "add-recipe"){
        //         if (runLang === "EN") {
        //             selectEN();
        //             inputsRecipeEN();
        //
        //             let myServDB = enDbSelectServing[0];
        //             let myServe = document.getElementById(myServDB[0]);
        //             myServe.innerHTML = myServDB[1];
        //
        //             for(let a = 0; a < enDbAdd.length; a++){
        //                 let elem = enDbAdd[a];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //
        //             for(let b = 0; b < enDbAddNamePlace.length; b++){
        //                 let elem = enDbAddNamePlace[b];
        //                 document.getElementById(elem[0]).placeholder = elem[1];
        //             }
        //
        //             for(let c = 0; c < 15; c++){
        //                 let myName = 'select-unit' + (c + 1);
        //                 const myUnit = document.getElementById(myName);
        //                 // console.log('My Unit Select: ' + myUnit.id);
        //                 let myNr = 0;
        //                 for(let d = 0; d < myUnit.children.length; d++){
        //                     let childId = myUnit.children[d].id;
        //
        //                     if(childId !== ''){
        //                         console.log('My Child ID: ' + childId);
        //
        //                         let myChild = myUnit.children[d];
        //
        //                         let itm = enDbSelectUnit[myNr];
        //                         myChild.title = itm[1];
        //                         myChild.innerHTML = itm[2];
        //
        //                         myNr++
        //                     }
        //                 }
        //             }
        //
        //             let myStepDB = enDbAddStepsPlace[0]
        //             const myStep = document.querySelectorAll(myStepDB[0]);
        //             myStep.forEach(elem=> {
        //                 elem.placeholder = myStepDB[1];
        //             })
        //
        //             let myStepBtnImgDB = enDbAddUploadImageStep[0]
        //             const myStepBtnImg = document.querySelectorAll(myStepBtnImgDB[0]);
        //             myStepBtnImg.forEach(elem=> {
        //                 elem.innerHTML = myStepBtnImgDB[1];
        //             })
        //         }
        //         if (runLang === "NL") {
        //             selectNL();
        //             inputsRecipeNL();
        //
        //             let myServDB = nlDbSelectServing[0];
        //             let myServe = document.getElementById(myServDB[0]);
        //             myServe.innerHTML = myServDB[1];
        //
        //             for(let a = 0; a < nlDbAdd.length; a++){
        //                 let elem = nlDbAdd[a];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //
        //             for(let b = 0; b < nlDbAddNamePlace.length; b++){
        //                 let elem = nlDbAddNamePlace[b];
        //                 document.getElementById(elem[0]).placeholder = elem[1];
        //             }
        //
        //             for(let c = 0; c < 15; c++){
        //                 let myName = 'select-unit' + (c + 1);
        //                 const myUnit = document.getElementById(myName);
        //                 // console.log('My Unit Select: ' + myUnit.id);
        //                 let myNr = 0;
        //                 for(let d = 0; d < myUnit.children.length; d++){
        //                     let childId = myUnit.children[d].id;
        //
        //                     if(childId !== ''){
        //                         console.log('My Child ID: ' + childId);
        //
        //                         let myChild = myUnit.children[d];
        //
        //                         let itm = nlDbSelectUnit[myNr];
        //                         myChild.title = itm[1];
        //                         myChild.innerHTML = itm[2];
        //
        //                         myNr++
        //                     }
        //                 }
        //             }
        //
        //             let myStepDB = nlDbAddStepsPlace[0]
        //             const myStep = document.querySelectorAll(myStepDB[0]);
        //             myStep.forEach(elem=> {
        //                 elem.placeholder = myStepDB[1];
        //             })
        //
        //             let myStepBtnImgDB = nlDbAddUploadImageStep[0]
        //             const myStepBtnImg = document.querySelectorAll(myStepBtnImgDB[0]);
        //             myStepBtnImg.forEach(elem=> {
        //                 elem.innerHTML = myStepBtnImgDB[1];
        //             })
        //         }
        //         if (runLang === "GR") {
        //             selectGR();
        //             inputsRecipeGR();
        //
        //             let myServDB = grDbSelectServing[0];
        //             let myServe = document.getElementById(myServDB[0]);
        //             myServe.innerHTML = myServDB[1];
        //
        //             for(let a = 0; a < grDbAdd.length; a++){
        //                 let elem = grDbAdd[a];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //
        //             for(let b = 0; b < grDbAddNamePlace.length; b++){
        //                 let elem = grDbAddNamePlace[b];
        //                 document.getElementById(elem[0]).placeholder = elem[1];
        //             }
        //
        //             for(let c = 0; c < 15; c++){
        //                 let myName = 'select-unit' + (c + 1);
        //                 const myUnit = document.getElementById(myName);
        //                 // console.log('My Unit Select: ' + myUnit.id);
        //                 let myNr = 0;
        //                 for(let d = 0; d < myUnit.children.length; d++){
        //                     let childId = myUnit.children[d].id;
        //
        //                     if(childId !== ''){
        //                         console.log('My Child ID: ' + childId);
        //
        //                         let myChild = myUnit.children[d];
        //
        //                         let itm = grDbSelectUnit[myNr];
        //                         myChild.title = itm[1];
        //                         myChild.innerHTML = itm[2];
        //
        //                         myNr++
        //                     }
        //                 }
        //             }
        //
        //             let myStepDB = grDbAddStepsPlace[0]
        //             const myStep = document.querySelectorAll(myStepDB[0]);
        //             myStep.forEach(elem=> {
        //                 elem.placeholder = myStepDB[1];
        //             })
        //
        //             let myStepBtnImgDB = grDbAddUploadImageStep[0]
        //             const myStepBtnImg = document.querySelectorAll(myStepBtnImgDB[0]);
        //             myStepBtnImg.forEach(elem=> {
        //                 elem.innerHTML = myStepBtnImgDB[1];
        //             })
        //
        //         }
        //     }
        //
        //     if (actor === "view-recipe"){
        //         if (runLang === "EN") {
        //
        //             for(let a = 0; a < enDbView.length; a++){
        //                 let elem = enDbView[a];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //         if (runLang === "NL") {
        //
        //             for(let a = 0; a < nlDbView.length; a++){
        //                 let elem = nlDbView[a];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //         }
        //         if (runLang === "GR") {
        //
        //             for(let a = 0; a < grDbView.length; a++){
        //                 let elem = grDbView[a];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //
        //         }
        //     }
        //
        //     if (actor === "edit-recipe"){
        //         if (runLang === "EN") {
        //             selectSkipEN();
        //             inputsRecipeEN();
        //
        //             let myServDB = enDbSelectServing[0];
        //             let myServe = document.getElementById(myServDB[0]);
        //             myServe.innerHTML = myServDB[1];
        //
        //             for(let a = 0; a < enDbEdit.length; a++){
        //                 let elem = enDbEdit[a];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //
        //             let myUplDB = enDbEditBtnUpload[0]
        //             const myUpl = document.querySelectorAll(myUplDB[0]);
        //             myUpl.forEach(elem=> {
        //                 elem.innerHTML = myUplDB[1];
        //             })
        //
        //             let myRepDB = enDbEditBtnReplace[0]
        //             const myRep = document.querySelectorAll(myRepDB[0]);
        //             myRep.forEach(elem=> {
        //                 elem.innerHTML = myRepDB[1];
        //             })
        //
        //             let myUplStepDB = enDbEditBtnStepUpload[0]
        //             const myUplStep = document.querySelectorAll(myUplStepDB[0]);
        //             myUplStep.forEach(elem=> {
        //                 elem.innerHTML = myUplStepDB[1];
        //             })
        //
        //             let myRepStepDB = enDbEditBtnStepReplace[0]
        //             const myRepStep = document.querySelectorAll(myRepStepDB[0]);
        //             myRepStep.forEach(elem=> {
        //                 elem.innerHTML = myRepStepDB[1];
        //             })
        //
        //             let myStepDB = enDbAddStepsEditPlace[0]
        //             const myStep = document.querySelectorAll(myStepDB[0]);
        //             myStep.forEach(elem=> {
        //                 elem.placeholder = myStepDB[1];
        //             })
        //
        //             // for(let b = 0; b < enDbAddNamePlace.length; b++){
        //             //     let elem = enDbAddNamePlace[b];
        //             //     document.getElementById(elem[0]).placeholder = elem[1];
        //             // }
        //
        //
        //
        //             for(let c = 0; c < 15; c++){
        //                 let myName = 'select-unit' + (c + 1);
        //                 const myUnit = document.getElementById(myName);
        //                 // console.log('My Unit Select: ' + myUnit.id);
        //                 let myNr = 0;
        //                 for(let d = 0; d < myUnit.children.length; d++){
        //                     let childId = myUnit.children[d].id;
        //
        //                     if(childId !== ''){
        //                         console.log('My Child ID: ' + childId);
        //
        //                         let myChild = myUnit.children[d];
        //
        //                         let itm = enDbSelectUnit[myNr];
        //                         myChild.title = itm[1];
        //                         myChild.innerHTML = itm[2];
        //
        //                         myNr++
        //                     }
        //                 }
        //             }
        //             //
        //             // let myStepDB = enDbAddStepsPlace[0]
        //             // const myStep = document.querySelectorAll(myStepDB[0]);
        //             // myStep.forEach(elem=> {
        //             //     elem.placeholder = myStepDB[1];
        //             // })
        //             //
        //             // let myStepBtnImgDB = enDbAddUploadImageStep[0]
        //             // const myStepBtnImg = document.querySelectorAll(myStepBtnImgDB[0]);
        //             // myStepBtnImg.forEach(elem=> {
        //             //     elem.innerHTML = myStepBtnImgDB[1];
        //             // })
        //         }
        //         if (runLang === "NL") {
        //             selectSkipNL();
        //             inputsRecipeNL();
        //
        //             let myServDB = nlDbSelectServing[0];
        //             let myServe = document.getElementById(myServDB[0]);
        //             myServe.innerHTML = myServDB[1];
        //
        //             for(let a = 0; a < nlDbEdit.length; a++){
        //                 let elem = nlDbEdit[a];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //
        //             let myUplDB = nlDbEditBtnUpload[0]
        //             const myUpl = document.querySelectorAll(myUplDB[0]);
        //             myUpl.forEach(elem=> {
        //                 elem.innerHTML = myUplDB[1];
        //             })
        //
        //             let myRepDB = nlDbEditBtnReplace[0]
        //             const myRep = document.querySelectorAll(myRepDB[0]);
        //             myRep.forEach(elem=> {
        //                 elem.innerHTML = myRepDB[1];
        //             })
        //
        //             let myUplStepDB = nlDbEditBtnStepUpload[0]
        //             const myUplStep = document.querySelectorAll(myUplStepDB[0]);
        //             myUplStep.forEach(elem=> {
        //                 elem.innerHTML = myUplStepDB[1];
        //             })
        //
        //             let myRepStepDB = nlDbEditBtnStepReplace[0]
        //             const myRepStep = document.querySelectorAll(myRepStepDB[0]);
        //             myRepStep.forEach(elem=> {
        //                 elem.innerHTML = myRepStepDB[1];
        //             })
        //
        //             let myStepDB = nlDbAddStepsEditPlace[0]
        //             const myStep = document.querySelectorAll(myStepDB[0]);
        //             myStep.forEach(elem=> {
        //                 elem.placeholder = myStepDB[1];
        //             })
        //
        //             // for(let b = 0; b < nlDbAddNamePlace.length; b++){
        //             //     let elem = nlDbAddNamePlace[b];
        //             //     document.getElementById(elem[0]).placeholder = elem[1];
        //             // }
        //
        //             // let myReqDB = nlDbAddRequirePlace[0]
        //             // const myReq = document.querySelectorAll(myReqDB[0]);
        //             // myReq.forEach(elem=> {
        //             //     elem.placeholder = myReqDB[1];
        //             // })
        //             //
        //             // let myIngDB = nlDbAddIngredientPlace[0]
        //             // const myIngr = document.querySelectorAll(myIngDB[0]);
        //             // myIngr.forEach(elem=> {
        //             //     elem.placeholder = myIngDB[1];
        //             // })
        //             //
        //             // let myAmtDB = nlDbAddAmountPlace[0]
        //             // const myAmt = document.querySelectorAll(myAmtDB[0]);
        //             // myAmt.forEach(elem=> {
        //             //     elem.placeholder = myAmtDB[1];
        //             // })
        //
        //             for(let c = 0; c < 15; c++){
        //                 let myName = 'select-unit' + (c + 1);
        //                 const myUnit = document.getElementById(myName);
        //                 // console.log('My Unit Select: ' + myUnit.id);
        //                 let myNr = 0;
        //                 for(let d = 0; d < myUnit.children.length; d++){
        //                     let childId = myUnit.children[d].id;
        //
        //                     if(childId !== ''){
        //                         console.log('My Child ID: ' + childId);
        //
        //                         let myChild = myUnit.children[d];
        //
        //                         let itm = nlDbSelectUnit[myNr];
        //                         myChild.title = itm[1];
        //                         myChild.innerHTML = itm[2];
        //
        //                         myNr++
        //                     }
        //                 }
        //             }
        //             //
        //             // let myStepDB = nlDbAddStepsPlace[0]
        //             // const myStep = document.querySelectorAll(myStepDB[0]);
        //             // myStep.forEach(elem=> {
        //             //     elem.placeholder = myStepDB[1];
        //             // })
        //             //
        //             // let myStepBtnImgDB = nlDbAddUploadImageStep[0]
        //             // const myStepBtnImg = document.querySelectorAll(myStepBtnImgDB[0]);
        //             // myStepBtnImg.forEach(elem=> {
        //             //     elem.innerHTML = myStepBtnImgDB[1];
        //             // })
        //         }
        //         if (runLang === "GR") {
        //             selectSkipGR();
        //             inputsRecipeGR();
        //
        //             let myServDB = grDbSelectServing[0];
        //             let myServe = document.getElementById(myServDB[0]);
        //             myServe.innerHTML = myServDB[1];
        //
        //             for(let a = 0; a < grDbEdit.length; a++){
        //                 let elem = grDbEdit[a];
        //                 document.getElementById(elem[0]).innerHTML = elem[1];
        //             }
        //
        //             let myUplDB = grDbEditBtnUpload[0]
        //             const myUpl = document.querySelectorAll(myUplDB[0]);
        //             myUpl.forEach(elem=> {
        //                 elem.innerHTML = myUplDB[1];
        //             })
        //
        //             let myRepDB = grDbEditBtnReplace[0]
        //             const myRep = document.querySelectorAll(myRepDB[0]);
        //             myRep.forEach(elem=> {
        //                 elem.innerHTML = myRepDB[1];
        //             })
        //
        //             let myUplStepDB = grDbEditBtnStepUpload[0]
        //             const myUplStep = document.querySelectorAll(myUplStepDB[0]);
        //             myUplStep.forEach(elem=> {
        //                 elem.innerHTML = myUplStepDB[1];
        //             })
        //
        //             let myRepStepDB = grDbEditBtnStepReplace[0]
        //             const myRepStep = document.querySelectorAll(myRepStepDB[0]);
        //             myRepStep.forEach(elem=> {
        //                 elem.innerHTML = myRepStepDB[1];
        //             })
        //
        //             let myStepDB = grDbAddStepsEditPlace[0]
        //             const myStep = document.querySelectorAll(myStepDB[0]);
        //             myStep.forEach(elem=> {
        //                 elem.placeholder = myStepDB[1];
        //             })
        //
        //
        //             //
        //             // for(let b = 0; b < grDbAddNamePlace.length; b++){
        //             //     let elem = grDbAddNamePlace[b];
        //             //     document.getElementById(elem[0]).placeholder = elem[1];
        //             // }
        //             //
        //             // let myReqDB = grDbAddRequirePlace[0]
        //             // const myReq = document.querySelectorAll(myReqDB[0]);
        //             // myReq.forEach(elem=> {
        //             //     elem.placeholder = myReqDB[1];
        //             // })
        //             //
        //             // let myIngDB = grDbAddIngredientPlace[0]
        //             // const myIngr = document.querySelectorAll(myIngDB[0]);
        //             // myIngr.forEach(elem=> {
        //             //     elem.placeholder = myIngDB[1];
        //             // })
        //             //
        //             // let myAmtDB = grDbAddAmountPlace[0]
        //             // const myAmt = document.querySelectorAll(myAmtDB[0]);
        //             // myAmt.forEach(elem=> {
        //             //     elem.placeholder = myAmtDB[1];
        //             // })
        //             //
        //             for(let c = 0; c < 15; c++){
        //                 let myName = 'select-unit' + (c + 1);
        //                 const myUnit = document.getElementById(myName);
        //                 // console.log('My Unit Select: ' + myUnit.id);
        //                 let myNr = 0;
        //                 for(let d = 0; d < myUnit.children.length; d++){
        //                     let childId = myUnit.children[d].id;
        //
        //                     if(childId !== ''){
        //                         console.log('My Child ID: ' + childId);
        //
        //                         let myChild = myUnit.children[d];
        //
        //                         let itm = grDbSelectUnit[myNr];
        //                         myChild.title = itm[1];
        //                         myChild.innerHTML = itm[2];
        //
        //                         myNr++
        //                     }
        //                 }
        //             }
        //             //
        //             // let myStepDB = grDbAddStepsPlace[0]
        //             // const myStep = document.querySelectorAll(myStepDB[0]);
        //             // myStep.forEach(elem=> {
        //             //     elem.placeholder = myStepDB[1];
        //             // })
        //             //
        //             // let myStepBtnImgDB = grDbAddUploadImageStep[0]
        //             // const myStepBtnImg = document.querySelectorAll(myStepBtnImgDB[0]);
        //             // myStepBtnImg.forEach(elem=> {
        //             //     elem.innerHTML = myStepBtnImgDB[1];
        //             // })
        //
        //         }
        //     }
        // }

        /** .: Page Selector :.**/
        // if (actor === "recipe-management"){
        //
        //     if(userLanguage === null){
        //         clickBtnLanguageMenu(defaultLanguage);
        //     }else{
        //         clickBtnLanguageMenu(userLanguage);
        //     }
        //
        //     const selectInputAjax = document.querySelectorAll('select');
        //     selectInputAjax.forEach(function(mySelect) {
        //         mySelect.addEventListener('change', indexAjaxSort);
        //     });
        //
        //     let btnAddRecipe =  document.getElementById("btn-add-recipe");
        //     btnAddRecipe.addEventListener("click", clickAddRecipe);
        //
        // }


        /** .: SCREEN - Listeners :.**/
        window.addEventListener('resize', onWindowResize, true);

        function getOrientation(){
            if (window.matchMedia("(orientation: portrait)").matches) {
                // you're in PORTRAIT mode
                window.location.reload();
            }

            if (window.matchMedia("(orientation: landscape)").matches) {
                // you're in LANDSCAPE mode
                window.location.reload();
            }
        }
        window.addEventListener('orientationchange', getOrientation);
    }

    /** .: COMPILE - UI Containers :.**/
    function HTML(actor) {

        initScene(arrObjects)

        /** .: STYLE - UI Containers :.**/
        // function showTheatre(){
        //     divTheatre.style.display = "block";
        // }
        divApp.style.position = 'absolute'
        divApp.style.top = '0'
        divApp.style.left = '0'
        divApp.style.display = 'flex'
        divApp.style.flexDirection = 'column'
        // divApp.style.height = 'max-content'
        divApp.style.backgroundColor = ioiC.greenDark

        divHero.style.position = 'relative'
        divHero.style.display = 'flex'

        heroLeft.style.position = 'relative'
        heroLeft.style.backgroundColor = ioiC.black

        // heroRight.style.position = 'relative'
        heroRight.style.backgroundColor = ioiC.purple

        canvasEarth.style.width = 100 + '%'
        canvasEarth.style.height = 100 + '%'

        section1.style.position = 'relative'
        section1.style.backgroundColor = ioiC.yellow

        section2.style.position = 'relative'
        section2.style.backgroundColor = ioiC.orange

        section3.style.position = 'relative'
        section3.style.backgroundColor = ioiC.yellow



        // function styleActor(actor){
        //     actor.style.display = "block";
        //     actor.style.overflowX = "hidden";
        // }

        /** .: CONSTRUCT - UI Containers :.**/
        // if (actor === "recipe-management"){
        //     createHeadBasic(actor);
        //     createFootBasic();
        //     fetchActorIndex();
        //     styleActor(divActor);
        //
        //     colorPage(actor);
        //
        //     showTheatre();
        // }

    }

    /** .: RESPONSIVE - UI :.**/
    function INIT(actor) {
        /** .: User - OS & Browser Detection :.**/
        function getBrowser() {
          if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
            return('Opera');
          } else if (navigator.userAgent.indexOf("Edg") != -1) {
            return('Edge');
          } else if (navigator.userAgent.indexOf("Chrome") != -1) {
            return('Chrome');
          } else if (navigator.userAgent.indexOf("Safari") != -1) {
            return('Safari');
          } else if (navigator.userAgent.indexOf("Firefox") != -1) {
            return('Firefox');
          } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) //IF IE > 10
          {
            return('IE');
          } else {
            return('unknown');
          }
        }

        function getOS() {
            let uA = navigator.userAgent || navigator.vendor ;
            if ((/iPad|iPhone|iPod/.test(uA) && !window.MSStream) || (uA.includes('Mac') && 'ontouchend' in document)) return 'iOS';
            let i, os = ['Windows', 'Android', 'Unix', 'Mac', 'Linux', 'BlackBerry'];
            for (i = 0; i < os.length; i++) if (new RegExp(os[i],'i').test(uA)) return os[i];
        }
        let myOs = getOS()
        let myBrowser = getBrowser()

        console.log('My OS: ' + myOs)
        console.log('My Browser: ' + myBrowser)

        // .: PAGE - Variables Responsive
        /** .: Page - Responsive Variables :.**/
        // let headRowHeight, headSpacerRowHeight, actorRowHeight, footRowHeight, footRowTop, footSpacerTop;

        let pageWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        let pageHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        // let pageWidth = window.innerWidth
        // let pageHeight = window.innerHeight

        let widthPercent = pageWidth / 100;
        let heightPercent = pageHeight / 100;

        let fontSize = pageHeight * 0.00195

        divApp.style.width = pageWidth + 'px'
        // divApp.style.height = 'max-content'

        divHero.style.width = pageWidth + 'px'
        divHero.style.height = pageHeight + 'px'

        section1.style.width = pageWidth + 'px'
        section1.style.height = pageHeight * 0.1 + 'px'

        section2.style.width = pageWidth + 'px'
        section2.style.height = pageHeight * 0.1 + 'px'

        section3.style.width = pageWidth + 'px'
        section3.style.height = pageHeight * 0.1 + 'px'

        clientOs.innerText = 'Client OS: ' + myOs
        clientBrowser.innerText = 'Client Browser: ' + myBrowser
        clientPixels.innerText = 'Client Dimensions: ' + pageWidth + 'x' + pageHeight

        function windowsLandscape(){

            if (pageWidth <= 1920) {
                heroTxtChallenge.style.fontSize = (fontSize * 1.3) + 'vw'
                heroTxtBy.style.fontSize = (fontSize * 0.7) + 'vw'
                heroTxtGlobal.style.fontSize = (fontSize * 2.3) + 'vw'
                heroTxtText.style.fontSize = (fontSize * 0.7) + 'vw'
            }
            if (pageWidth >= 1921) {
                heroTxtChallenge.style.fontSize = (fontSize * 0.8) + 'vw'
                heroTxtBy.style.fontSize = (fontSize * 0.5) + 'vw'
                heroTxtGlobal.style.fontSize = (fontSize * 1.6) + 'vw'
                heroTxtText.style.fontSize = (fontSize * 0.5) + 'vw'
            }
        }
        function androidLandscape(){
            // iPad
            if (pageWidth === 1080 && pageHeight === 810) {
                heroTxtChallenge.style.fontSize = (fontSize * 1.1) + 'vw'
                heroTxtBy.style.fontSize = (fontSize * 0.9) + 'vw'
                heroTxtGlobal.style.fontSize = (fontSize * 2.6) + 'vw'
                heroTxtText.style.fontSize = (fontSize * 0.9) + 'vw'
            }
            // iPhone 11 PRO
            if (pageWidth === 812) {
                heroTxtChallenge.style.fontSize = (fontSize * 2.7) + 'vw'
                heroTxtBy.style.fontSize = (fontSize * 1.7) + 'vw'
                heroTxtGlobal.style.fontSize = (fontSize * 3.7) + 'vw'
                heroTxtText.style.fontSize = (fontSize * 1.8) + 'vw'
            }
            // iPhone 11 PRO MAX
            if (pageWidth === 896) {
                heroTxtChallenge.style.fontSize = (fontSize * 2.6) + 'vw'
                heroTxtBy.style.fontSize = (fontSize * 1.6) + 'vw'
                heroTxtGlobal.style.fontSize = (fontSize * 3.6) + 'vw'
                heroTxtText.style.fontSize = (fontSize * 1.6) + 'vw'
            }
            // iPhone 12/13 MINI || iPhone 12/13 PRO
            if (pageWidth === 812 || pageWidth === 844) {
                heroTxtChallenge.style.fontSize = (fontSize * 2.8) + 'vw'
                heroTxtBy.style.fontSize = (fontSize * 1.8) + 'vw'
                heroTxtGlobal.style.fontSize = (fontSize * 3.8) + 'vw'
                heroTxtText.style.fontSize = (fontSize * 1.8) + 'vw'
            }
            // iPhone 12/13 PRO MAX
            if (pageWidth === 926) {
                heroTxtChallenge.style.fontSize = (fontSize * 2.6) + 'vw'
                heroTxtBy.style.fontSize = (fontSize * 1.6) + 'vw'
                heroTxtGlobal.style.fontSize = (fontSize * 3.6) + 'vw'
                heroTxtText.style.fontSize = (fontSize * 1.6) + 'vw'
            }
            // iPhone SE
            if (pageWidth === 667) {
                heroTxtChallenge.style.fontSize = (fontSize * 3.2) + 'vw'
                heroTxtBy.style.fontSize = (fontSize * 2.1) + 'vw'
                heroTxtGlobal.style.fontSize = (fontSize * 5.0) + 'vw'
                heroTxtText.style.fontSize = (fontSize * 2.1) + 'vw'
            }
        }
        function androidPortrait(){
                    // iPad
                    if (pageWidth === 810) {
                        heroTxtChallenge.style.fontSize = (fontSize * 1.4) + 'vw'
                        heroTxtBy.style.fontSize = (fontSize * 0.9) + 'vw'
                        heroTxtGlobal.style.fontSize = (fontSize * 3.2) + 'vw'
                        heroTxtText.style.fontSize = (fontSize * 0.9) + 'vw'
                    }
                    // iPhone 11 PRO
                    if (pageWidth === 375) {

                        heroTxtGlobal.style.marginTop = 2 + 'vh'
                        heroTxtText.style.marginTop = 2.5 + 'vh'

                        heroTxtChallenge.style.fontSize = (fontSize * 2.7) + 'vw'
                        heroTxtBy.style.fontSize = (fontSize * 1.7) + 'vw'
                        heroTxtGlobal.style.fontSize = (fontSize * 3.7) + 'vw'
                        heroTxtText.style.fontSize = (fontSize * 1.7) + 'vw'

                    }
                    // iPhone 11 PRO MAX
                    if (pageWidth === 414) {
                        heroTxtChallenge.style.fontSize = (fontSize * 2.6) + 'vw'
                        heroTxtBy.style.fontSize = (fontSize * 1.8) + 'vw'
                        heroTxtGlobal.style.fontSize = (fontSize * 3.9) + 'vw'
                        heroTxtText.style.fontSize = (fontSize * 1.9) + 'vw'
                    }
                    // iPhone 12/13 MINI || iPhone 12/13 PRO
                    if (pageWidth === 375 || pageWidth === 390) {
                        heroTxtChallenge.style.fontSize = (fontSize * 2.9) + 'vw'
                        heroTxtBy.style.fontSize = (fontSize * 1.9) + 'vw'
                        heroTxtGlobal.style.fontSize = (fontSize * 3.9) + 'vw'
                        heroTxtText.style.fontSize = (fontSize * 1.9) + 'vw'
                    }
                    // iPhone 12/13 PRO MAX
                    if (pageWidth === 428) {
                        heroTxtChallenge.style.fontSize = (fontSize * 2.6) + 'vw'
                        heroTxtBy.style.fontSize = (fontSize * 1.6) + 'vw'
                        heroTxtGlobal.style.fontSize = (fontSize * 3.7) + 'vw'
                        heroTxtText.style.fontSize = (fontSize * 1.6) + 'vw'
                    }
                    // iPhone SE
                    if (pageWidth === 375) {
                        heroTxtChallenge.style.fontSize = (fontSize * 3.4) + 'vw'
                        heroTxtBy.style.fontSize = (fontSize * 2.4) + 'vw'
                        heroTxtGlobal.style.fontSize = (fontSize * 5.0) + 'vw'
                        heroTxtText.style.fontSize = (fontSize * 2.4) + 'vw'
                    }
                }
        function appleLandscape(){
            // iPad
            if (pageWidth === 1080 && pageHeight === 810) {
                heroTxtChallenge.style.fontSize = (fontSize * 1.1) + 'vw'
                heroTxtBy.style.fontSize = (fontSize * 0.9) + 'vw'
                heroTxtGlobal.style.fontSize = (fontSize * 2.6) + 'vw'
                heroTxtText.style.fontSize = (fontSize * 0.9) + 'vw'
            }
            // iPhone 11 PRO
            if (pageWidth === 812) {
                heroTxtChallenge.style.fontSize = (fontSize * 2.7) + 'vw'
                heroTxtBy.style.fontSize = (fontSize * 1.7) + 'vw'
                heroTxtGlobal.style.fontSize = (fontSize * 3.7) + 'vw'
                heroTxtText.style.fontSize = (fontSize * 1.8) + 'vw'
            }
            // iPhone 11 PRO MAX
            if (pageWidth === 896) {
                heroTxtChallenge.style.fontSize = (fontSize * 2.6) + 'vw'
                heroTxtBy.style.fontSize = (fontSize * 1.6) + 'vw'
                heroTxtGlobal.style.fontSize = (fontSize * 3.6) + 'vw'
                heroTxtText.style.fontSize = (fontSize * 1.6) + 'vw'
            }
            // iPhone 12/13 MINI || iPhone 12/13 PRO
            if (pageWidth === 812 || pageWidth === 844) {
                heroTxtChallenge.style.fontSize = (fontSize * 2.8) + 'vw'
                heroTxtBy.style.fontSize = (fontSize * 1.8) + 'vw'
                heroTxtGlobal.style.fontSize = (fontSize * 3.8) + 'vw'
                heroTxtText.style.fontSize = (fontSize * 1.8) + 'vw'
            }
            // iPhone 12/13 PRO MAX
            if (pageWidth === 926) {
                heroTxtChallenge.style.fontSize = (fontSize * 2.6) + 'vw'
                heroTxtBy.style.fontSize = (fontSize * 1.6) + 'vw'
                heroTxtGlobal.style.fontSize = (fontSize * 3.6) + 'vw'
                heroTxtText.style.fontSize = (fontSize * 1.6) + 'vw'
            }
            // iPhone SE
            if (pageWidth === 667) {
                heroTxtChallenge.style.fontSize = (fontSize * 3.2) + 'vw'
                heroTxtBy.style.fontSize = (fontSize * 2.1) + 'vw'
                heroTxtGlobal.style.fontSize = (fontSize * 5.0) + 'vw'
                heroTxtText.style.fontSize = (fontSize * 2.1) + 'vw'
            }
        }
        function applePortrait(){
            // iPad
            if (pageWidth === 810) {
                heroTxtChallenge.style.fontSize = (fontSize * 1.4) + 'vw'
                heroTxtBy.style.fontSize = (fontSize * 0.9) + 'vw'
                heroTxtGlobal.style.fontSize = (fontSize * 3.2) + 'vw'
                heroTxtText.style.fontSize = (fontSize * 0.9) + 'vw'
            }
            // iPhone 11 PRO
            if (pageWidth === 375) {

                heroTxtGlobal.style.marginTop = 2 + 'vh'
                heroTxtText.style.marginTop = 2.5 + 'vh'

                heroTxtChallenge.style.fontSize = (fontSize * 2.7) + 'vw'
                heroTxtBy.style.fontSize = (fontSize * 1.7) + 'vw'
                heroTxtGlobal.style.fontSize = (fontSize * 3.7) + 'vw'
                heroTxtText.style.fontSize = (fontSize * 1.7) + 'vw'

            }
            // iPhone 11 PRO MAX
            if (pageWidth === 414) {
                heroTxtChallenge.style.fontSize = (fontSize * 2.6) + 'vw'
                heroTxtBy.style.fontSize = (fontSize * 1.8) + 'vw'
                heroTxtGlobal.style.fontSize = (fontSize * 3.9) + 'vw'
                heroTxtText.style.fontSize = (fontSize * 1.9) + 'vw'
            }
            // iPhone 12/13 MINI || iPhone 12/13 PRO
            if (pageWidth === 375 || pageWidth === 390) {
                heroTxtChallenge.style.fontSize = (fontSize * 2.9) + 'vw'
                heroTxtBy.style.fontSize = (fontSize * 1.9) + 'vw'
                heroTxtGlobal.style.fontSize = (fontSize * 3.9) + 'vw'
                heroTxtText.style.fontSize = (fontSize * 1.9) + 'vw'
            }
            // iPhone 12/13 PRO MAX
            if (pageWidth === 428) {
                heroTxtChallenge.style.fontSize = (fontSize * 2.6) + 'vw'
                heroTxtBy.style.fontSize = (fontSize * 1.6) + 'vw'
                heroTxtGlobal.style.fontSize = (fontSize * 3.7) + 'vw'
                heroTxtText.style.fontSize = (fontSize * 1.6) + 'vw'
            }
            // iPhone SE
            if (pageWidth === 375) {
                heroTxtChallenge.style.fontSize = (fontSize * 3.4) + 'vw'
                heroTxtBy.style.fontSize = (fontSize * 2.4) + 'vw'
                heroTxtGlobal.style.fontSize = (fontSize * 5.0) + 'vw'
                heroTxtText.style.fontSize = (fontSize * 2.4) + 'vw'
            }
        }

        if (pageWidth <= pageHeight) {
            // console.log("Windows Device: Portrait");
            divHero.style.flexDirection = 'column'

            heroLeft.style.width = 100 + '%'
            heroLeft.style.height = pageHeight * 0.5 + 'px'
            heroLeft.style.top = pageHeight * 0.5 + 'px'
            heroLeft.style.left = '0'

            heroRight.style.position = 'absolute'
            heroRight.style.top = '0'
            heroRight.style.left = '0'
            heroRight.style.width = pageWidth + 'px'
            heroRight.style.height = pageHeight * 0.5 + 'px'

            if(myOs === 'Android'){
                androidPortrait()
            }
            if(myOs === 'iOS'){
                applePortrait()
            }
            if(myOs === 'Windows'){
                windowsPortrait()
            }


        }
        if (pageWidth >= pageHeight) {
            // console.log("Windows Device: Landscape");

            divHero.style.flexDirection = 'row'

            heroLeft.style.top = '0'
            heroLeft.style.left = '0'
            heroLeft.style.width = 50 + '%'
            heroLeft.style.height = 100 + '%'

            heroRight.style.position = 'relative'
            heroRight.style.width = 50 + '%'
            heroRight.style.height = 100 + '%'

            if(myOs === 'Android'){
                androidPortrait()
            }
            if(myOs === 'iOS'){
                appleLandscape()
            }
            if(myOs === 'Windows'){
                windowsLandscape()
            }
        }

        // divTheatre.style.width = pageWidth + "px";
        // divTheatre.style.height = pageHeight + "px";

        // console.log(pageWidth)





        let screenWidth = heroRight.clientWidth;
        let screenHeight = heroRight.clientHeight;
        renderer.setSize(screenWidth, screenHeight);
        camera.aspect = screenWidth / screenHeight;
        camera.updateProjectionMatrix();

        /** .: Scaling - OS & Browser :.**/
        // if(myOs === "Android"){
        //     // console.log("Android Device")
        //     // portrait full = 800x1160
        //     // portrait key = 800x789
        //     // landscape full = 1280x680
        //     // landscape key = 1280x309
        //     if(pageWidth === 800){
        //         console.log("Android Device: Portrait");
        //     }
        //     if(pageWidth === 1280){
        //         console.log("Android Device: Landscape");
        //     }
        // }
        // if(myOs === "Windows") {
        //     // console.log("Windows Device")
        //     if (pageWidth < pageHeight) {
        //         // console.log("Windows Device: Portrait");
        //     }
        //     if (pageWidth <= 1920) {
        //         // console.log("Windows Device: Landscape => 1080p");
        //
        //         // // Head
        //         // headRowHeight = heightPercent * 14;
        //         //
        //         // // Actor
        //         // actorRowHeight = heightPercent * 80;
        //         //
        //         // // Foot
        //         // footRowHeight = heightPercent * 6;
        //     }
        //     if (pageWidth >= 1921) {
        //         console.log("Windows Device: Landscape => 4K");
        //
        //         divApp.style.width = pageWidth + 'px'
        //         // divApp.style.height = 'max-content'
        //
        //         divHero.style.width = pageWidth + 'px'
        //         divHero.style.height = pageHeight + 'px'
        //
        //         section1.style.width = pageWidth + 'px'
        //         section1.style.height = pageHeight * 0.1 + 'px'
        //
        //         section2.style.width = pageWidth + 'px'
        //         section2.style.height = pageHeight * 0.1 + 'px'
        //
        //         section3.style.width = pageWidth + 'px'
        //         section3.style.height = pageHeight * 0.1 + 'px'
        //
        //         // // Head
        //         // headRowHeight = heightPercent * 14;
        //         //
        //         // // Actor
        //         // actorRowHeight = heightPercent * 80;
        //         //
        //         // // Foot
        //         // footRowHeight = heightPercent * 6;
        //     }
        // }

        /** .: Page - Functions :.**/
        // function setHtmlFrame(){
        //     divHead.style.height = headRowHeight + "px";
        //
        //     divActor.style.width = (pageWidth * 0.9) + "px";
        //     divActor.style.left = (pageWidth * 0.05) + "px";
        //     divActor.style.height = actorRowHeight + "px";
        //
        //     divFoot.style.height = footRowHeight + "px";
        // }
    }

    /** .: RUN LISTENER :.**/
    LISTENER(initLoad)

    function onWindowResize() {
        // console.log("__ON_RESIZE__");

        // let myChildren = document.getElementById("theatre-container").children;
        let myActor = 'test'
        // for (let i = 0; i < myChildren.length; i++) {
        //     let child = myChildren[i].id;
        //     let prefixActor = child.slice(0, 6);
        //     if (prefixActor === "actor-") {
        //         myActor = child.slice(6, 50);
        //     }
        // }


        INIT(myActor)
    }
}
MyApp(initPage)
/////////////////////////////////////////////////////////

/** .: JAVASCRIPT Functions :.**/
function removeAllChildNodes(parent) {
    while (parent.firstChild) {
        parent.removeChild(parent.firstChild);
    }
}
function stripSlashes(string){
    return string.replace(/\\/g, '');
}

/** .: AJAX Functions :.**/
function resultIIndexSearchTitle() {
    let myValue = document.getElementById("inp-search-title").value;
    console.log(myValue);

    if (myValue !== "") {

        $.ajax({
            url: "./box/php/engine/load-index-search.php",
            method: "POST",
            data: {
                myTitle: myValue
            },
            success: function (data) {

                data = JSON.parse(data);
                console.log(data);
                // console.log(Array.isArray([data]));




                // console.log(Object.keys(data));

                // for (const [key, value] of Object.entries(data)) {
                //     console.log(`${key}: ${value}`);
                // }
                //     let myItem = (`${key}: ${value}`);
                //     console.log("My Object: " + myItem["0"]);
                //     // console.log(data["title"]);
                //     let row =  data[key1]
                //     // console.log("My Row; " + row);
                //     // console.log(value);
                //     // console.log(Object.keys(data));
                // }

                let i = 0;
                for(i; i < data.length; i++){
                    let myTitle = data[0]['title'];
                    myTitle = myTitle.replace(/\\/g, '');
                    console.log("My Title: " + i + " - " + myTitle);
                }


            }
        })
    }
}
