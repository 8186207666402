let ioiC
ioiC = {
    // scrollColor1: "#2b2a33",
    // scrollColor2: "#131313",
    // scrollColor3: "#202020",

    theatreColor: "#002740",
    // theatreColor: "white",
    actorColor: "#001c2d",
    actorBorderColor: "#005d8c",
    actorTxtColor: "white",

    headRowColor: "rgb(1, 20, 48)",
    categoryColor: "#00A9FD",

    placeholder: "rgb(132, 127, 59)",

    listTitleBgColor: "#002740",
    listTitleColor: "#00A9FD",
    listTitleAccentColor: "#00A9FD",

    inpTxtBgColor: "#002740",
    inpTxtSelectColor: "rgb(255, 255, 255)",

    btnUiColor: "#1d559e",
    btnUiTxtColor: "white",
    btnUiColorHover: "rgb(21, 98, 1)",

    btnUploadColor: "rgb(29, 85, 158)",
    btnUploadDoneColor: "rgb(132, 127, 59)",
    btnUploadTxtColor: "white",

    footColor: "rgb(1, 20, 48)",


    // Different set
    titleColor: "#00A9FD",

    btnUiTextColor: "#e2e2e2",
    btnUiTextColorHover: "#ffffff",

    purple: "rgb(101, 0, 253)",
    magenta: "rgb(254,0,194)",
    red: "rgb(255, 0, 0)",
    green: "rgb(0, 255, 0)",
    greenDark: "rgb(0,102,0)",
    blue: "rgb(0, 0, 255)",
    orange: "rgb(255,144,0)",
    yellow: "rgb(255,255,0)",
    cinta: "rgb(131, 249, 5)",
    ioi: "rgb(7, 149, 255)",
    blueLight: "rgb(0,161,255)",
    blueDark: "rgb(0,103,255)",
    blueDarker: "rgb(0,64,255)",
    blueMarine: "rgb(0,0,149)",
    blueRoyal: "rgb(0,0,81)",
    blueBlack: "rgb(0,0,49)",
    white: "rgb(255, 255, 255)",
    black: "rgb(0, 0, 0)",

    fireFox_black: "#1c1b22",
    fireFox_grey_dark: "#2b2a33",
    fireFox_grey_light: "#fbfbfe",
    win10_dark: "#202020",
    win10_dark_light: "#191919",
    win10_dark_grey_light: "#808080",
    win10_dark_grey_light2: "#a2a2a2",
    win10_dark_grey_light3: "#2b2b2b",

    // theatreColor: "#002740",
    // actorColor: "#001c2d",
    // // actorColor: "limegreen",
    // actorColor2: "rgb(1, 20, 48)",
    // titleColor: "#00A9FD",
    // placeholder: "#847f3b",
    // btnUiColor: "#1d559e",
    // btnUiColorHover: "rgb(21, 98, 1)",
    // btnUiTextColor: "#e2e2e2",
    // btnUiTextColorHover: "#ffffff"

}

export {ioiC}

//
// let myCredits = ".: Tonia's Delicacies © :.";
//
// function colorPage(actor){
//     // .: THEATRE
//     divTheatre = document.getElementById('theatre-container');
//     divTheatre.style.backgroundColor = ioiC.theatreColor;
//
//     // .: ACTOR
//     divActor.style.borderTop = "5px solid " + ioiC.actorBorderColor;
//
//     // .: Actor - CONTENT
//     let divContent = document.getElementById('div-content');
//     divContent.style.backgroundColor = ioiC.actorColor;
//
//     let divContent2 = document.querySelectorAll('.index-list-b, .index-list-c, .div-recipe-ingredients, ' +
//         '.recipe-row-step, .view-recipe-row, .view-recipe-title-row, .view-head-items-right, ' +
//         '.view-recipe-steps-row, .edit-row-step, .div-language '); // .div-language
//     divContent2.forEach(elem=>{
//         elem.style.backgroundColor = ioiC.headRowColor;
//     });
//
//     // .: Theatre - ELEMENTS
//     let inpTxt = document.querySelectorAll('input[type="text"], input[type="time"], select, textarea, ' +
//         '.div-img-view-title, .view-recipe-step-text, .div-img-view, .img-recipe-edit');
//     inpTxt.forEach(elem=>{
//         elem.style.backgroundColor = ioiC.inpTxtBgColor;
//     });
//     let btnUI = document.querySelectorAll('.btn-ui, .btn-ui-large');
//     btnUI.forEach(elem=>{
//         elem.style.backgroundColor = ioiC.btnUiColor;
//         elem.style.color = ioiC.btnUiTxtColor;
//     });
//     let btnUpload = document.querySelectorAll('.btn-upload-img, .btn-upload-step-img');
//     btnUpload.forEach(elem=>{
//         elem.style.backgroundColor = ioiC.btnUploadColor;
//         elem.style.color = ioiC.btnUploadTxtColor;
//     });
//
//     let contentHead = document.querySelectorAll('.div-content-head');
//     contentHead.forEach(elem=>{
//         elem.style.backgroundColor = ioiC.actorBorderColor;
//     });
//     let contentHeadRow = document.querySelectorAll('.div-content-head-row');
//     contentHeadRow.forEach(elem=>{
//         elem.style.backgroundColor = ioiC.headRowColor;
//     });
//
//     let listTitle = document.querySelectorAll('.ttl-list, .ttl-list-small, .ttl-list-small-steps, .view-title-block');
//     listTitle.forEach(elem=>{
//         elem.style.backgroundColor = ioiC.listTitleBgColor;
//         elem.style.color = ioiC.listTitleColor;
//         elem.style.borderLeft = 'thin solid ' + ioiC.listTitleAccentColor;
//     });
//
//     let stepTextView = document.querySelectorAll('.view-recipe-step-text, .inp-edit-recipe-step-text');
//     stepTextView.forEach(elem=>{
//         elem.style.backgroundColor = ioiC.inpTxtBgColor;
//         elem.style.color = ioiC.actorTxtColor;
//     });
//
//     let lblHead = document.querySelectorAll('.lbl-head');
//     lblHead.forEach(elem=>{
//         elem.style.color = ioiC.placeholder;
//     });
//
//     let ttlView = document.querySelectorAll('.ttl-view');
//     ttlView.forEach(elem=>{
//         elem.style.color = ioiC.white;
//     });
//
//     let ttlCat = document.querySelectorAll('.view-category-block-left');
//     ttlCat.forEach(elem=>{
//         elem.style.color = ioiC.white;
//     });
//     let ttlCatItems = document.querySelectorAll('.view-category-block-right');
//     ttlCatItems.forEach(elem=>{
//         elem.style.color = ioiC.categoryColor;
//     });
//
//     // .: FOOT
//     divFoot.style.backgroundColor = ioiC.footColor;
//
//
//     if (actor === "recipe-management"){
//
//     }
//     if (actor === "add-recipe"){
//
//     }
//     if (actor === "edit-recipe"){
//
//     }
//     if (actor === "view-recipe"){
//
//         let myViewHead = document.getElementById('view-recipe-head-row');
//         myViewHead.style.backgroundColor = ioiC.actorBorderColor
//
//     }

// }