import * as THREE from "three";
import {arrObjects} from './scene_objects.js'
import {scene} from './scene.js'
import {Float32BufferAttribute} from "three";

let stars
export default ()=>{
    // starsGroup = new THREE.Group()

    const starGeometry = new THREE.BufferGeometry()
    const starMaterial = new THREE.PointsMaterial(
        {
            color: 0xffffff
        }
    )
    const starVertices = []
    for(let stars = 0; stars < 5000; stars++){
        const x = (Math.random() - 0.5) * 2000
        const y = (Math.random() - 0.5) * 2000
        const z = (Math.random() - 0.5) * 2000
        // const z = -Math.random() * 2000
        starVertices.push(x, y, z)
    }

    starGeometry.setAttribute('position', new THREE.Float32BufferAttribute(starVertices, 3))

    stars = new THREE.Points(starGeometry, starMaterial)
    scene.add(stars)
}
export {stars}

