import * as THREE from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import myStars from './scene_stars.js'
import {stars} from './scene_stars.js'
import initGui from './scene_gui.js'
import {myGui} from './scene_gui.js'
import {sphere, clouds} from './scene_objects.js'
// import {sphere} from './scene_objects.js'
import Stats from 'three/examples/jsm/libs/stats.module'

let scene, camera, renderer
export default (obj)=>{

    // Canvas
    const canvas = document.querySelector('canvas.webgl')

    // Scene
    scene = new THREE.Scene()
    // console.log(scene.light)

    /**
     * :. Sizes
     */
    const sceneSize = {
        width: canvas.clientWidth,
        height: canvas.clientHeight
    }

    /**
     * :. Camera
     */
    // const camera = new THREE.PerspectiveCamera(45, sceneSize.width / sceneSize.height, 0.1, 1000);
    camera = new THREE.PerspectiveCamera(45, sceneSize.width / sceneSize.height, 0.1, 1000);
    // camera.position.set( 7, 4, 18 );
    camera.position.set( 0, 0, 18 );
    scene.add(camera)

    /**
     * :. Objects
     */
    if(obj != null){
        obj.forEach((elem) => scene.add(elem));
    }
    myStars()

    // atmosphere.lookAt(camera)

    /**
     * :. Renderer
     */
    renderer = new THREE.WebGLRenderer(
        {
            canvas: canvas,
            antialias: true,
            setFaceCulling: true
        })

    renderer.setSize(sceneSize.width, sceneSize.height);
    // renderer.setFaceCulling (false)
    renderer.render(scene, camera)

    /**
     * :. Camera Control
     */
    // const controls = new OrbitControls( camera, renderer.domElement );
    // controls.update();

    /**
     * :. Lights
     */
    // scene.remove(scene.getObjectByName(‘Light’));
    // const ambientLight = new THREE.AmbientLight(0xffffff, 1.0);
    // ambientLight.name = 'myAmbientLight';
    // scene.add(ambientLight);

    // const lightIntensity = 0.01
    // const dirLight1 = new THREE.DirectionalLight(0xffffff, lightIntensity);
    // dirLight1.name = 'myDirectionLight1';
    // dirLight1.position.set(-5, 5, 5);
    // dirLight1.lookAt(0, 0, 0);
    // scene.add(dirLight1);

    // const dirLight2 = new THREE.DirectionalLight(0xffffff, lightIntensity);
    // dirLight2.name = 'myDirectionLight2';
    // dirLight2.position.set(5, -5, 5);
    // dirLight2.lookAt(0, 0, 0);
    // scene.add(dirLight2);
    //
    // const dirLight3 = new THREE.DirectionalLight(0xffffff, lightIntensity);
    // dirLight3.name = 'myDirectionLight3';
    // dirLight3.position.set(0, 0, -25);
    // dirLight3.lookAt(0, 0, 0);
    // scene.add(dirLight3);

    /**
     * :. Helpers
     */
    // Light Helpers
    // const spotLightHelper = new THREE.SpotLightHelper(  );
    // scene.add( spotLightHelper );

    // const helperLight1 = new THREE.DirectionalLightHelper( dirLight1, 1 );
    // scene.add( helperLight1 );

    // const helperLight2 = new THREE.DirectionalLightHelper( dirLight2, 1 );
    // scene.add( helperLight2 );
    //
    // const helperLight3 = new THREE.DirectionalLightHelper( dirLight3, 1 );
    // scene.add( helperLight3 );

    // // Axe Helper
    // const axesHelper = new THREE.AxesHelper( 5 );
    // scene.add( axesHelper );

    // Grid Helper
    // const gridSize = 10;
    // const gridDivisions = 10;
    // const gridHelper = new THREE.GridHelper( gridSize, gridDivisions, 0x444444, 0x222222 );
    // scene.add( gridHelper );

    // Performance Stats
    const stats = new Stats()
    document.body.appendChild(stats.dom)

    /**
     * :. RUN - GUI
     */
    // initGui()

    /**
     * :. Animate Function
     */
    function animate() {
        requestAnimationFrame(animate);

        // sphere.rotation.x += 0.00072
        stars.rotation.x -= 0.0001
        stars.rotation.y -= 0.0001
        stars.rotation.z += 0.0001

        sphere.rotation.y += 0.00031
        clouds.rotation.x -= 0.00001
        clouds.rotation.y -= 0.000001
        clouds.rotation.z += 0.0001
        // sphere.rotation.z += 0.0001

        // controls.update();
        stats.update();
        // material.needsUpdate

        renderer.render(scene, camera);
        camera.updateProjectionMatrix();
    }
    animate();
}

function resizeScene(){
    let guiWidth
    let screenWidth = window.innerWidth;
    let screenHeight = window.innerHeight;
    renderer.setSize(screenWidth, screenHeight);
    camera.aspect = screenWidth / screenHeight;
    camera.updateProjectionMatrix();

    if(screenWidth <= 400){
        guiWidth = screenWidth * 0.75;
    }else{
        guiWidth = screenWidth * 0.1;
    }

    // myGui.width = guiWidth
}

// :. EXPORTS
export {scene, camera, renderer}
// export {scene, camera, resizeScene}
