varying vec3 normalVertexEarth;

void main(){

    float fade = 0.61;
    float distance = 1.05;
//    float intensity = pow(1.0 - dot(normalVertexEarth, vec3(0, 0, 0.5)), 1.0);
    float intensity = pow(fade - dot(normalVertexEarth, vec3(0, 0, distance)), 3.5);
//    float intensity = 0.5;
    gl_FragColor = vec4(0.05, 0.5, 1.3, 1.0) * (intensity);
//    Gl_FrontFacing = vec4(0.05, 0.5, 1.0, 1.0) * intensity;


}

