import * as THREE from "three";
// import {camera} from "./scene.js";
import shrEarthVtx from './shaders/shdr_earth_vtx.glsl'
import shrEarthFrag from './shaders/shdr_earth_frag.glsl'
// console.log(shrEarthFrag)
import shrCloudsVtx from './shaders/shdr_clouds_vtx.glsl'
import shrCloudsFrag from './shaders/shdr_clouds_frag.glsl'
import shrAtmosphereVtx from './shaders/shdr_atmosphere_vtx.glsl'
import shrAtmosphereFrag from './shaders/shdr_atmosphere_frag.glsl'

let arrObjects = []
let atmosphere, sphere, clouds, globeGroup

globeGroup = new THREE.Group()
globeGroup.rotation.set(0.175, 0.0, -0.175)

sphere = new THREE.Mesh(
    new THREE.SphereGeometry(3.9, 75, 75),
    new THREE.ShaderMaterial({
        // color: 0xff0000
        // map: new THREE.TextureLoader().load('./texture_earth.jpg')
        vertexShader: shrEarthVtx,
        fragmentShader: shrEarthFrag,
        uniforms: {
            earthTexture: {
                value: new THREE.TextureLoader().load('./img/texture_earth.jpg')
            }
        },
        side: THREE.CullFaceFront

    })
)
sphere.rotation.y = -2.4
globeGroup.add(sphere)
// arrObjects.push(sphere)

clouds = new THREE.Mesh(
    new THREE.SphereGeometry(3.9, 75, 75),
    new THREE.ShaderMaterial({
        // color: 0xff0000
        // map: new THREE.TextureLoader().load('./texture_earth.jpg')
        vertexShader: shrCloudsVtx,
        fragmentShader: shrCloudsFrag,
        uniforms: {
            cloudsTexture: {
                value: new THREE.TextureLoader().load('./img/texture_earth_clouds.jpg'),
            }
        },
        blending: THREE.AdditiveBlending,
        side: THREE.CullFaceFront

    })
)
clouds.scale.set(1.001, 1.001, 1.001)
// clouds.rotation.y = -2.5943951024
clouds.rotation.set(-0.175, -2.5943951024, -0.175)
globeGroup.add(clouds)
arrObjects.push(globeGroup)

atmosphere = new THREE.Mesh(
    new THREE.SphereGeometry(3.9, 50, 50),
    new THREE.ShaderMaterial({
        vertexShader: shrAtmosphereVtx,
        fragmentShader: shrAtmosphereFrag,
        blending: THREE.AdditiveBlending,
        side: THREE.BackSide

    })
)
atmosphere.scale.set(1.2, 1.2, 1.2)
// atmosphere.lookAt(camera)
arrObjects.push(atmosphere)

// const geometry = new THREE.BoxGeometry(0.5, 0.5, 0.5)
// const material = new THREE.MeshBasicMaterial(
//     {
//         color: 0xff00ff,
//         wireframe: true
//     })
// const mesh = new THREE.Mesh(geometry, material)
// mesh.position.set(2,0,0)
//
// arrObjects.push(mesh)
//
// const geometry1 = new THREE.BoxGeometry(0.5, 0.5, 0.5)
// const material1 = new THREE.MeshBasicMaterial({ color: 0x0000ff })
// const mesh1 = new THREE.Mesh(geometry1, material1)
// mesh1.position.set(0,0,0)
//
// arrObjects.push(mesh1)

// :. EXPORTS
export {arrObjects, atmosphere, sphere, clouds}

