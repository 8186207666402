uniform sampler2D cloudsTexture;
varying vec2 uvVertexClouds;
varying vec3 normalVertexClouds;
// :.  ADD Color Filter
//    gl_FragColor = vec4(vec3(0.01,0.01,0.01) + texture2D(earthTexture, uvVertexEarth).xyz, 1.0);

// :.  ADD Intensity and Atmosphere
void main(){

    float intensity = 0.95 - dot(normalVertexClouds, vec3(0.0, 0.0, 1.0));
//    float intensity = 0.8;
    vec3 atmosphere = vec3(0.1, 0.3, 0.5) * pow(intensity, 1.0);
    gl_FragColor = vec4(atmosphere + texture2D(cloudsTexture, uvVertexClouds).xyz, 100.2);
}