uniform sampler2D earthTexture;
varying vec2 uvVertexEarth;
varying vec3 normalVertexEarth;
// :.  ADD Color Filter
//    gl_FragColor = vec4(vec3(0.01,0.01,0.01) + texture2D(earthTexture, uvVertexEarth).xyz, 1.0);

// :.  ADD Intensity and Atmosphere
void main(){

    float intensity = 0.95 - dot(normalVertexEarth, vec3(0.0, 0.0, 1.0));
//    float intensity = 0.8;
    vec3 atmosphere = vec3(0.1, 0.3, 0.5) * pow(intensity, 1.0);
    gl_FragColor = vec4(atmosphere + texture2D(earthTexture, uvVertexEarth).xyz, 1.5);
}